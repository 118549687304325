<template>
    <div class="container">
        <div class="floating__save">
            <div class="save-btn"><button type="button"><img src="../../assets/resource/icon/save-btn.png" alt="중간저장" @click="save"></button></div>
        </div>
        <div class="sub-tit">
            <!--<h2></h2>-->
        </div>
        <div class="inner">
            <div class="join-tab">
                <ul class="join-tab__list">
                    <li class="join-tab__item">
                        <div class="step"><span class="text">step </span><span class="num">01</span></div>
                        <div class="tit">개인정보입력</div>
                    </li>
                    <li class="join-tab__item ">
                        <div class="step"><span class="text">step </span><span class="num">02</span></div>
                        <div class="tit">학력 및 경력사항 등 입력</div>
                    </li>
                    <li class="join-tab__item active">
                        <div class="step"><span class="text">step </span><span class="num">03</span></div>
                        <div class="tit">자기소개서 작성</div>
                    </li>
                    <li class="join-tab__item ">
                        <div class="step"><span class="text">step </span><span class="num">04</span></div>
                        <div class="tit">입사지원 완료</div>
                    </li>
                </ul>
            </div>
            <div class="input-form type1">
                <div class="text-box type1 info-text mt20">
                    <div class="input-form__top-wrap">
                        <p class="input-form__top-text type2">- 개인정보 보호를 위해 중간저장 후 일정시간<b class="c-red">(60분)</b>이 경과할 경우 작성한 내용이 자동삭제 됩니다. </p>
                        <p class="input-form__top-text type2">- 기존 작성된 내용을 입력하시는 경우, 메모장에 붙여넣기 하신 후 진행하여 주시기 바랍니다.</p>
                    </div>
                </div>
            </div>

            <div class="contents">
                <div class="input-form type1">
                    <div class="info-text depth__wrap depth01">
                        <div class="depth__bullet">
                            <span class="bullet01"></span>
                        </div>
                        <div class="depth__text">
                            <h4 class="depth01__title title">자기소개</h4>
                        </div>
                    </div>
                    <ul class="input-form__list">
                        <li class="input-form__item desc">
                            <div class="input-form__box">
                                <div class="tit" style="display: flex; align-items: center;">성장과정 및 학교생활</div>
                                <div class="cont">
                                    <p class="small-text">{{ raiGrowth.length }}/500자</p>
                                    <textarea v-model="raiGrowth" ref="raiGrowth" @input="updateTextAreaHeight('raiGrowth', $event)" maxlength="500" class="auto-grow-textarea"></textarea>
                                </div>
                            </div>
                        </li>
                        <li class="input-form__item desc">
                            <div class="input-form__box">
                                <div class="tit" style="display: flex; align-items: center;">지원한 분야와 <br> 관련한 자신의 장점 및 보완점</div>
                                <div class="cont">
                                    <p class="small-text">{{ raiStrength.length }}/500자</p>
                                    <textarea v-model="raiStrength" ref="raiStrength" @input="updateTextAreaHeight('raiStrength', $event)" maxlength="500" class="auto-grow-textarea"></textarea>
                                </div>
                            </div>
                        </li>
                        <li class="input-form__item desc">
                            <div class="input-form__box">
                                <div class="tit" style="display: flex; align-items: center;">성격 및 특기사항</div>
                                <div class="cont">
                                    <p class="small-text">{{ raiSpecialty.length }}/500자</p>
                                    <textarea v-model="raiSpecialty" ref="raiSpecialty" @input="updateTextAreaHeight('raiSpecialty', $event)" maxlength="500" class="auto-grow-textarea"></textarea>
                                </div>
                            </div>
                        </li>
                        <li class="input-form__item desc">
                            <div class="input-form__box">
                                <div class="tit" style="display: flex; align-items: center;">지원 동기 및 <br> 입사 후 포부/장래 계획</div>
                                <div class="cont">
                                    <p class="small-text">{{ raiPlan.length }}/500자</p>
                                    <textarea v-model="raiPlan" ref="raiPlan" @input="updateTextAreaHeight('raiPlan', $event)" maxlength="500" class="auto-grow-textarea"></textarea>
                                </div>
                            </div>
                        </li>
                        <li class="input-form__item desc">
                            <div class="input-form__box">
                                <div class="tit" style="display: flex; align-items: center;">건강상태 <br>(최근 질병 또는 사고로 치료를 받았거나 과거 질병이 있었던 경우 작성)</div>
                                <div class="cont">
                                    <p class="small-text">{{ raiHealth.length }}/500자</p>
                                    <textarea v-model="raiHealth" ref="raiHealth" @input="updateTextAreaHeight('raiHealth', $event)" maxlength="500" class="auto-grow-textarea"></textarea>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <p class="desc" style="margin-top: 20px;">* 위 내용은 사실과 틀림없으며 기재사실이 허위로 판명될 경우에는 합격취소 또는 임용취소 처분을 감수 할 것을 서약하면서 응시지원서를 제출합니다.</p>
                </div>
            </div>
            <div class="contents text-box type1 info-text">
                <ul class="text-box__list">
                    <li class="text-box__item">이전단계 수정이 필요하실 경우 중간저장 혹은 최종 제출 하신 후 입사지원 > 수정/조회 페이지에서 수정가능합니다.</li>
                    <li class="text-box__item">공용 PC에서 접속하셨을 경우 개인정보보호를 위해 입력완료 후 브라우저 창을 종료해주시기 바랍니다.</li>
                </ul>
            </div>
            <!-- <div class="">
                <div class="save-btn"><button type="button"><img src="../../assets/resource/icon/save-btn.png" alt="중간저장" @click="save"></button></div>
                <div class="goTop"><a href="#"><img src="../../assets/resource/icon/top_btn.png" alt="상단이동"></a></div>
            </div> -->
            <div class="contents btn-wrap center">
                <a href="javascript:;" class="btn" @click="save" id="save">중간저장</a>
                <a href="javascript:;" class="btn blue" @click="goNextStep">최종제출</a>
            </div>

            <ExtentionTimePopup ref="extentionTimePopup" :raNo="this.raNo"></ExtentionTimePopup>

        </div>
    </div>
</template>

<script>
    import ExtentionTimePopup from "@/components/common/ExtentionTimePopup.vue";

    export default {
        components: {ExtentionTimePopup},
        data() {
            return {
                rnNo: this.$route.query.rnNo,
                // raNo: this.$route.query.raNo,
                raNo: '',
                type: this.$route.query.type,
                raiGrowth: '',
                raiStrength: '',
                raiSpecialty: '',
                raiPlan: '',
                raiHealth: '',
                applicantInfo: {},
            };
        },
        methods: {
            async getRecruitApplicantView() {
                await this.axios.get(this.api.recruit.applicant.view, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data) {
                        this.applicantInfo = res.data

                        if (!this.applicantInfo.submitDt) { // 중간 저장 후 55분 경과시 연장팝업 노출
                            this.$refs.extentionTimePopup.setModifyTime(this.applicantInfo.modifiedDt)
                        }

                    } else {
                        alert("해당 지원정보가 없습니다.")
                    }
                });
            },
            async getIntroductionInfo() {
                await this.axios.get(this.api.recruit.applicant.introductionView, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data) {
                        this.raiGrowth = res.data.raiGrowth
                        this.raiStrength = res.data.raiStrength
                        this.raiSpecialty = res.data.raiSpecialty
                        this.raiPlan = res.data.raiPlan
                        this.raiHealth = res.data.raiHealth
                    }
                });
            },
            submit(mode) {
                const params = new URLSearchParams();
                params.append('raNo', this.raNo);
                params.append('raiGrowth', this.raiGrowth);
                params.append('raiStrength', this.raiStrength);
                params.append('raiSpecialty', this.raiSpecialty);
                params.append('raiPlan', this.raiPlan);
                params.append('raiHealth', this.raiHealth);

                this.axios.post(this.api.recruit.applicant.introductionRegister, params)
                    .then(res => {
                        console.log("data: " + JSON.stringify(res.data));
                        if (res.data.code == '0000') {
                            console.log("pass 1");
                            // 입력시간연장
                            if (!this.applicantInfo.submitDt) {
                                this.$refs.extentionTimePopup.extensionTime()
                            }
                            if (mode === 'goNextStep') {
                                console.log("pass 2");
                                // alert('지원자의 자기소개정보가 저장되었습니다.');
                                this.axios.post(this.api.recruit.applicant.submit, params)  // 최종제출 상태값 변경
                                    .then(res => {
                                        console.log("pass 3");
                                        console.log("data: " + JSON.stringify(res.data));
                                        if (res.data.code == '0000') {
                                            this.$store.commit('setRaNo', this.raNo)
                                            this.$router.push({ path: '/application/applicationStep4.do', query: { rnNo: this.rnNo, type: 'update' } })
                                        } else {
                                            alert(res.data.message);
                                        }
                                    })
                            } else {
                                alert('중간저장이 완료되었습니다.');
                            }
                        } else {
                            alert(res.data.message);
                        }
                    })
            },
            save() {
                this.submit()
            },
            goNextStep() {
                if (!this.raiGrowth) {
                    alert("성장과정 및 학교생활을 입력해주세요.");
                    this.$refs.raiGrowth.scrollIntoView({ block: 'center' })

                    return
                }
                if (!this.raiStrength) {
                    alert("지원한 분야와 관련한 자신의 장점 및 보완점을 입력해주세요.");
                    this.$refs.raiStrength.scrollIntoView({ block: 'center' })

                    return
                }
                if (!this.raiSpecialty) {
                    alert("성격 및 특기사항을 입력해주세요.");
                    this.$refs.raiSpecialty.scrollIntoView({ block: 'center' })

                    return
                }
                if (!this.raiPlan) {
                    alert("지원 동기 및 입사 후 포부/장래 계획을 입력해주세요.");
                    this.$refs.raiPlan.scrollIntoView({ block: 'center' })

                    return
                }
                /*if (!this.raiHealth) {
                    alert("건강상태를 입력해주세요.");
                    this.$refs.raiHealth.scrollIntoView({block: 'center'})

                    return
                }*/

                if (!confirm("위 내용은 사실과 틀림없으며 기재사실이 허위로 판명될 경우에는 \n합격취소 또는 임용취소 처분을 감수 할 것을 서약하면서 \n응시원서를 제출하시겠습니까?")) {
                    return
                }

                this.submit('goNextStep')
            },
            scrollHandler() {
                let targetElement = document.getElementById('save');
                if (targetElement) {
                    targetElement.focus();
                }
            },
            updateTextAreaHeight(refName, event) {
                const textarea = this.$refs[refName];
                textarea.style.height = 'auto'; // 현재 높이 초기화
                textarea.style.height = textarea.scrollHeight + 'px'; // 콘텐츠 높이에 맞게 조정

                this[refName] = this[refName].replace(/[<>{}[\]\\\/`'"$%&]/g, '')
            },
            adjustAllTextAreas() {
                const textareas = this.$el.querySelectorAll('.auto-grow-textarea');
                textareas.forEach(textarea => {
                    textarea.style.height = 'auto'; // 현재 높이 초기화
                    textarea.style.height = textarea.scrollHeight + 'px'; // 콘텐츠 높이에 맞게 조정
                });
            },
            async getRecruitNoticeView() {
                this.axios
                    .get(this.api.recruit.notice.view, {
                        params: {
                            rnNo: this.rnNo,
                            updateCntYn: 'N'
                        }
                    })
                    .then((res) => {
                        this.noticeInfo = res.data
                        if(this.noticeInfo == '' || !this.$commonUtils.isExpose(this.noticeInfo.exposeEndDt)){
                            alert('종료되었거나 조회되지 않는 게시글입니다.')
                            this.$router.push('/')
                        }
                    });
            },
        },
        async mounted() {
            this.adjustAllTextAreas();
            this.raNo = this.$store.state.applicantStore.raNo;
            // this.$store.commit('setRaNo', null)

            if (this.type === 'update' && !this.raNo) {
                alert("올바르지 않은 접근입니다.")
                this.$router.push({path: '/application/editApplication.do'})
            }

            if (this.rnNo) {
                await this.getRecruitNoticeView()
            }

            if (this.raNo) {
                await this.getRecruitApplicantView()
                await this.getIntroductionInfo()
            }

            window.addEventListener('scroll', this.scrollHandler);

            setTimeout(() => {
                gsap.to('.floating__save', {
                    scrollTrigger: {
                        trigger: '.container',
                        start: 'bottom-=0px bottom',
                        toggleClass: {targets: '.floating__save', className: 'on'},
                        scrub: true
                    }
                });
            }, 300);

        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.scrollHandler)
        }
    };
</script>